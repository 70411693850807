import React, { useEffect, useState } from 'react';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import styled, { keyframes } from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../services/sizeHelper';
import {
  getPanboxOrderAmount,
  getPaymentId,
  getPaymentType,
  PAYMENT_TYPE
} from '../utils/localStorage';
import { verifyPayment } from '../api';
import Routes from '../services/routes';
import { navigate } from 'gatsby';
import useFirebaseContext from '../hooks/useFirebaseContext';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: ${FULL_PAGE_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.5);
  border-top: 8px solid lightgrey;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

const VerifyPaymentPage = () => {
  const { user } = useFirebaseContext();
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    init();
  }, [user, inProgress]);

  const init = async () => {
    if (!user?.ssn || inProgress) return;

    setInProgress(true);

    const paymentId = getPaymentId();
    const orderAmount = getPanboxOrderAmount();
    const paymentType =
      (getPaymentType() as PAYMENT_TYPE) ?? PAYMENT_TYPE.DEFAULT_ORDER;

    if (paymentId) {
      const res = await verifyPayment({
        paymentId,
        ssn: user.ssn,
        orderAmount,
        type: paymentType
      });
      if (res.statusCode === 200) {
        navigate(Routes.PAYMENT_COMPLETED);
      } else {
        navigate(Routes.ERROR);
      }
    } else {
      navigate(Routes.LANDING_PAGE);
    }
  };
  return (
    <Wrapper>
      <Typography color="textSecondary" variant="h3" align="center">
        Verifierar betalning, var god vänta...
      </Typography>
      <Spacer spacing={3} />
      <Spinner />
    </Wrapper>
  );
};

export default VerifyPaymentPage;
